import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'utilities';
import './styles.scss';

class ActivityBrochure extends PureComponent {
  render() {
    return (
      <>
        <embed
          type="text/html"
          src="https://s3.ap-southeast-2.amazonaws.com/files.hakatours.com/Activity_Catalogue.pdf"
          style={{ width: '100dvw', height: 'calc(100dvh - 10px)' }}
        />
      </>
    );
  }
}
export default connect(mapStateToProps)(ActivityBrochure);
